<template>
  <LayoutBuyer
    :sidebarActiveItem="{
      value: 'order',
      children: sideBarItem,
    }"
  >
    <template v-slot:title>{{ title }}</template>
    <div class="p-2 p-md-5 d-flex justify-content-center">
      <div class="order-container">
        <template>
          <GoBackBtn v-if="isFromCheckout" :url="{ name: 'OrderRequestList' }">Back</GoBackBtn>
          <GoBackBtn v-else>Back</GoBackBtn>
        </template>
        <AppLoading v-if="!order"></AppLoading>
        <OrderViewPending
          v-else
          :order="order"
          :loading="loading"
          :admin="isAdminOrOwner"
          @edit="$router.push({ name: 'OrderEdit', params: { id: order.id } })"
          @decline="cancelRfq"
          @approve="approveRfq"
          @clone="cloneRfq"
        ></OrderViewPending>
      </div>
    </div>

    <SuccessModal
      v-model="canceled"
      @confirm="$router.push({ name: 'OrderRequestList' })"
    >
      <template v-slot:title> Order Canceled </template>
      <template v-slot:description>
        Click "Proceed" to return to Order list
      </template>
      <template v-slot:action-btn-text> Proceed </template>
    </SuccessModal>

    <SuccessModal
      v-model="approved"
      @confirm="$router.push({ name: 'OrderRequestList' })"
    >
      <template v-slot:title> Order Approved </template>
      <template v-slot:description>
        Click "Proceed" to return to Order list
      </template>
      <template v-slot:action-btn-text> Proceed </template>
    </SuccessModal>

    <SuccessModal
      v-model="cloned"
      @confirm="$router.push({ name: 'OrderRequestList' })"
    >
      <template v-slot:title> Order Cloned </template>
      <template v-slot:description>
        Click "Proceed" to return to Order list
      </template>
      <template v-slot:action-btn-text> Proceed </template>
    </SuccessModal>
  </LayoutBuyer>
</template>

<script>
import GoBackBtn from '@/components/Partial/GoBackBtn.vue';
import OrderViewPending from '@/components/Buyer/OrderView/OrderViewPending.vue';
import Orders from '@/shared/api/Orders';
import AppLoading from '@/shared/elements/AppLoading.vue';
import LayoutBuyer from '@/components/Partial/Layouts/LayoutBuyer/LayoutBuyer.vue';
import SuccessModal from '@/components/Partial/Modals/SuccessModal.vue';
import { STATUS } from '@/shared/constants/OrderConstants';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import { mapGetters } from 'vuex';

export default {
  name: 'OrderView',

  mixins: [_appApiHelper],

  components: {
    GoBackBtn,
    OrderViewPending,
    AppLoading,
    LayoutBuyer,
    SuccessModal,
  },

  data() {
    return {
      id: this.$route.params.id,
      order: null,
      canceled: false,
      approved: false,
      cloned: false,
      isFromCheckout: false,
    };
  },

  watch: {
    $route: {
      handler() {
        this.id = this.$route.params.id;
        this.getOrderDetails();
      },
      deep: true,
    },
  },



  methods: {
    async getOrderDetails() {
      const response = await Orders.getOrder(this.id);
      if (response.status === 200) {
        this.order = response.data;
      } else {
        alert('there was an error in your request. Please refresh the page');
      }
    },
    async approveRfq() {
      const status = STATUS.REQUEST_APPROVED.value;

      this.$store.dispatch('api/preventSuccessModal');
      const response = await Orders.editOrder(this.id, {
        status,
      });

      if (response.status === 200) {
        this.approved = true;
      }
    },
    async cancelRfq() {
      const status = STATUS.REQUEST_DECLINED.value;

      this.$store.dispatch('api/preventSuccessModal');
      const response = await Orders.editOrder(this.id, {
        status,
      });

      if (response.status === 200) {
        this.canceled = true;
      }
    },
    async cloneRfq() {
      const response = await Orders.cloneOrder(this.id);

      if (response.status === 200) {
        this.cloned = true;
      }
    },
  },

  computed: {
    ...mapGetters('auth', ['user']),

    isAdminOrOwner() {
      let groups = this.user.groups;

      if (groups === null || groups.length === 0 || !groups) {
        return false;
      }

      return groups.some((group) => {
        return ['Administrator', 'Owner'].includes(group.name);
      });
    },

    title() {
      if (!this.order || !this.order.status) {
        return 'RFQ';
      }

      return `${this.order.status} Order`;
    },

    sideBarItem() {
      if (!this.order || !this.order.status) {
        return [];
      }

      return [
        this.order.status === STATUS.REQUEST_APPROVED.value
          ? 'approved'
          : this.order.status === STATUS.COMPLETED.value
          ? 'completed'
          : this.order.status === STATUS.QUOTE_REJECTED.value
          ? 'rejected'
          : 'request',
      ];
    },
  },

  mounted() {
    let params = this.$route.params

    if (params.origin === 'OrderCheckout') {
      this.isFromCheckout = true
    }
    
    this.getOrderDetails();
    
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_breakpoints';
.order-container {
  @include media-lg('below') {
    width: 100%;
  }
}
</style>
